import React from "react";
import "./top-fill-polygon.scss";

const TopFillPolygon = () => {

    return (
        
        <div className="top-fill-polygon"></div>

    )
}

export default TopFillPolygon;