import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";
import BackgroundImage from "gatsby-background-image"

import CustomMarkdown from "../../utils/customMarkdown";
import './paragraph.scss';
import '../../styles/variables.scss';
import TopFillPolygon from "../top-fill-polygon/top-fill-polygon";
import BottomFillPolygon from "../bottom-fill-polygon/bottom-fill-polygon";

const Paragraph = ({ Content, Image, ImagePosition, BackgroundColor, PolygonBottom, PolygonTop, PaddingTop, PaddingBottom, Background, GradientColor }) => {

    var paragraphStyle = {};
    PaddingTop && PaddingBottom ? paragraphStyle = { padding: '50px 15px' }
        : PaddingTop ? paragraphStyle = { paddingTop: '50px', paddingLeft: '15px', paddingRight: '15px' }
            : PaddingBottom ? paragraphStyle = { paddingBottom: '50px', paddingLeft: '15px', paddingRight: '15px' }
                : paragraphStyle = {}

    switch (Image === null) {

        case true:
            //console.log(1)
            return (
                <>
                    {
                        PolygonTop ? <TopFillPolygon /> : ""
                    }
                    <div className={`paragraph-container ${BackgroundColor}`}>
                        <div className="paragraph-content" style={paragraphStyle}>
                            <CustomMarkdown children={Content}></CustomMarkdown>
                        </div>
                    </div>
                    {
                        PolygonBottom ? <BottomFillPolygon /> : ""

                    }
                </>
            )
        case false:
            //console.log(2)

            if (ImagePosition === "Right") {
                return (
                    <>
                        {
                            PolygonTop ? <TopFillPolygon /> : ""
                        }
                        <div className={`paragraph-container ${BackgroundColor}`}>
                            <div className="paragraph-content image-left-right" style={paragraphStyle}>
                                <div className="paragraph-text align-right">
                                    <CustomMarkdown children={Content} />
                                </div>
                                <div className="paragraph-image">
                                    <GatsbyImage image={Image?.gatsbyImageData} alt="How Comparison works - Step 1" className="item-image"></GatsbyImage>
                                </div>
                            </div>
                        </div>
                        {
                            PolygonBottom ? <BottomFillPolygon /> : ""

                        }
                    </>


                )
            }
            else if (ImagePosition === "Left") {
                //console.log(3)

                return (
                    <>
                        {
                            PolygonTop ? <TopFillPolygon /> : ""
                        }
                        <div className={`paragraph-container ${BackgroundColor}`}>
                            <div className="paragraph-content image-left-right" style={paragraphStyle}>
                                <div className="paragraph-image">
                                    <GatsbyImage image={Image?.gatsbyImageData} alt="How Comparison works - Step 1" className="item-image"></GatsbyImage>
                                </div>
                                <div className="paragraph-text align-left">
                                    <CustomMarkdown children={Content} />
                                </div>
                            </div>
                        </div>
                        {
                            PolygonBottom ? <BottomFillPolygon /> : ""

                        }
                    </>

                )
            }
            else if (ImagePosition === "Top") {
                //console.log(4)

                return (
                    <>
                        {
                            PolygonTop ? <TopFillPolygon /> : ""
                        }
                        {/* <TopFillPolygon /> */}
                        <div className={`paragraph-container ${BackgroundColor}`}>
                            <div className="paragraph-content" style={paragraphStyle}>
                                <GatsbyImage image={Image.gatsbyImageData} alt="How Comparison works - Step 1" />
                                <CustomMarkdown children={Content} className="about-th"></CustomMarkdown>
                            </div>
                        </div>
                        {
                            PolygonBottom ? <BottomFillPolygon /> : ""

                        }
                    </>


                )
            }
            else if (ImagePosition === "Bottom") {
                //console.log(5)

                return (
                    <>
                        {
                            PolygonTop ? <TopFillPolygon /> : ""
                        }
                        <div className={`paragraph-container ${BackgroundColor}`}>
                            <div className="paragraph-content" style={paragraphStyle}>
                                <CustomMarkdown children={Content}></CustomMarkdown>
                                <GatsbyImage image={Image.gatsbyImageData} alt="How comparison works - Step 1" />
                            </div>
                        </div>
                        {
                            PolygonBottom ? <BottomFillPolygon /> : ""
                        }
                    </>

                )
            }
            else if (ImagePosition === "FullWidth") {
                return (
                    <BackgroundImage Tag="section" role="img" className="bg-display-point" fluid={Background?.fluid || ["#0050a0"]}>
                        {
                            PolygonTop ? <TopFillPolygon /> : ""
                        }
                        <div className={`paragraph-container ${BackgroundColor}`}>
                            <div className={Background ? `paragraph-content-full-${GradientColor}` : "paragraph-content"} style={paragraphStyle}>
                                <GatsbyImage image={Image.gatsbyImageData} alt="" className="display-point" />
                                <CustomMarkdown children={Content} className={Background ? `paragraph-full-${GradientColor}` : ""}></CustomMarkdown>
                            </div>
                        </div>
                        {
                            PolygonBottom ? <BottomFillPolygon /> : ""
                        }
                    </BackgroundImage>

                )
            }
        default:
            return <p>Nothing to return</p>
    }
}

export default Paragraph;