import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import CustomMarkdown from "../../utils/customMarkdown";
import './timeline.scss';


const Timeline = ({ Content, Cards }) => {

    return (

        <div className="timeline-container">
            <div className="timeline-content width-1200 text-center padding">
                {
                    Content ? <CustomMarkdown children={Content} className="timeline-description"></CustomMarkdown> : ""
                }

            </div>
            <div className="timeline">
                {
                    Cards.map((item, index) => {

                        return (
                            <div className={`container ${index % 2 ? 'right' : 'left'}`} key={index}>
                                <div className="head">
                                    <GatsbyImage image={item.Image.gatsbyImageData} alt=""></GatsbyImage>
                                </div>
                                <div className="body">
                                    {
                                        item.Title ? <CustomMarkdown children={item.Title}></CustomMarkdown> : ""
                                    }
                                    <div className="line"></div>
                                    {
                                        item.Subtitle ? <CustomMarkdown children={item.Subtitle}></CustomMarkdown> : ""
                                    }
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}
export default Timeline;