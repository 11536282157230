import React from 'react';
import './image-cards.scss';
import { GatsbyImage } from "gatsby-plugin-image";



const ImageCard = ( {Image} ) => {
    return (

        <div className="card-container">
            <div className="card-content">
                {Image ?
                    <GatsbyImage image={Image?.gatsbyImageData} alt=""></GatsbyImage>
                    : ""}
                {/* <GatsbyImage image={Image?.localFile.childImageSharp.gatsbyImageData} alt=""></GatsbyImage> */}
            </div>
        </div>
    )
}

export default ImageCard;