import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import CustomMarkdown from "../../utils/customMarkdown";
import "../card-slider/card-slider.scss";
import { BsFillCheckCircleFill } from "react-icons/bs";

const CardSlider = ({ Content, Cards }) => {

    return (

        <>
            <div className="uk-slider uk-slider-container card-slider-container" uk-slider="center: true">
                <div className="padding width-800">
                    <div className="card-slider-header text-center">
                        {
                            Content ? <CustomMarkdown children={Content}></CustomMarkdown> : ""
                        }
                    </div>

                </div>

                <div className="card-slider">

                    <div className="uk-position-relative uk-visible-toggle" tabIndex="-1">

                        <div className="uk-slider-container uk-light">

                            <ul className="uk-slider-items uk-grid-match uk-grid">
                                {
                                    Cards.length ? Cards.map((item, index) => {
                                        return (
                                            <li className="uk-width-3-4 card-width" key={index}>
                                                <div className="uk-cover-container">
                                                    <div className="card-content display-flex">
                                                        <div className="card-image">
                                                            <GatsbyImage image={item.Image.gatsbyImageData} />
                                                        </div>
                                                        <div className="card-description">
                                                            {
                                                                item.Title ? <CustomMarkdown children={item.Title} className="green-color card-title"></CustomMarkdown> : ""
                                                            }
                                                            <div className="display-flex card-subtitle">
                                                                <div className="card-check">
                                                                    <BsFillCheckCircleFill />
                                                                </div>
                                                                <div className="card-subtitle-text">
                                                                    {
                                                                        item.Subtitle ? <CustomMarkdown children={item.Subtitle} className="grey-text-color"></CustomMarkdown> : ""
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    }) : ""
                                }

                            </ul>
                        </div>

                        <div className="uk-hidden@s uk-light">
                            <a className="uk-position-center-left uk-position-small icon-arrow" href="#" data-uk-slidenav-previous="true" uk-slider-item="previous" aria-label="prev"></a>
                            <a className="uk-position-center-right uk-position-small icon-arrow" href="#" data-uk-slidenav-next="true" uk-slider-item="next" aria-label="next"></a>
                        </div>

                        <div className="uk-visible@s">
                            <a className="uk-position-center-left-out uk-position-small display-icon-arrow" href="#" data-uk-slidenav-previous="true" uk-slider-item="previous" aria-label="prev"></a>
                            <a className="uk-position-center-right-out uk-position-small display-icon-arrow" href="#" data-uk-slidenav-next="true" uk-slider-item="next" aria-label="next"></a>
                        </div>



                    </div>


                    <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin" id="nav-dots"></ul>
                    {/* <ul className="uk-dotnav uk-flex-center uk-margin" id="nav-dots"><li className="uk-active"><a href="#" aria-label="dot-nav"></a></li></ul> */}
                    {/* <div>
                        <ul className="uk-dotnav uk-flex-center uk-margin" role="tablist" id="nav-dots">
                            <li className="uk-active"><div><a href="#"></a></div></li>
                        </ul>
                    </div> */}

                </div>
            </div>

        </>
    )
}
export default CardSlider;