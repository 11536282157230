import React from "react";
import "./accreditations.scss";

import { GatsbyImage } from "gatsby-plugin-image";
import CustomMarkdown from "../../utils/customMarkdown";

const Accreditations = ({ Images, Description }) => {

    return (

        <div className="uk-slider uk-slider-container slider-container" uk-slider="true">
            {
                Description ? <CustomMarkdown children={Description} className="description-markdown"></CustomMarkdown> : ""
            }
            <div className="uk-position-relative uk-visible-toggle slider-content" tabIndex="-1">

                <div className="uk-slider-container uk-light">
                    <ul className="uk-slider-items uk-child-width-1-1@xs uk-child-width-1-2@s uk-child-width-1-3@m">
                        {
                            Images ? Images.map((item, index) => {

                                return (
                                    <li key={index} className="image-item">
                                        <GatsbyImage image={item.Image.gatsbyImageData} alt="How Comparison works - Step 1" className="item-image"></GatsbyImage>
                                    </li>
                                )
                            }) : ""
                        }
                    </ul>
                </div>

                <div className="uk-hidden@s uk-light">
                    <a className="uk-position-center-left uk-position-small icon-arrow" href="#" data-uk-slidenav-previous="true" uk-slider-item="previous" aria-label="slide-prev"></a>
                    <a className="uk-position-center-right uk-position-small icon-arrow" href="#" data-uk-slidenav-next="true" uk-slider-item="next" aria-label="slide-next"></a>
                </div>

                <div className="uk-visible@s">
                    <a className="uk-position-center-left-out uk-position-small icon-arrow" href="#" data-uk-slidenav-previous="true" uk-slider-item="previous" aria-label="slide-prev"></a>
                    <a className="uk-position-center-right-out uk-position-small icon-arrow" href="#" data-uk-slidenav-next="true" uk-slider-item="next" aria-label="slide-next"></a>
                </div>

            </div>

            {/* <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul> */}

        </div>

    )
}

export default Accreditations;