import React from 'react';
import './image-component.scss';

import ImageCard from '../image-cards/image-cards';


const ImageComponent = ({ Images }) => {

    return (
        <div className="images">
            <div className="uk-child-width-1-4@l uk-child-width-1-3@m uk-child-width-1-2@s  uk-grid-match uk-grid uk-text-center" uk-grid="true" id="margin-cardpage">
                {

                    Images.map((item, index) => {

                        return (
                            <ImageCard
                                key={index}
                                Image={item.Image}
                                

                            />
                        )

                    })


                }
            </div>
        </div>

    )
}

export default ImageComponent;