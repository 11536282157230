import React from 'react';
import './price-card.scss';

import { GatsbyImage } from "gatsby-plugin-image";
import CustomMarkdown from "../../utils/customMarkdown";
import BottomFillPolygon from "../bottom-fill-polygon/bottom-fill-polygon";
import TopFillPolygon from "../top-fill-polygon/top-fill-polygon";


const PriceCard = ({ Type, Services, Discount, BackgroundColor, PolygonBottom, PolygonTop }) => {

    switch (Type) {
        case "price-component":
            let backgroundColor = "";

            switch (BackgroundColor) {
                case "White":
                    backgroundColor = "white-background";
                    break;
                case "Blue":
                    backgroundColor = "blue-background";
                    break;
                case "White":
                    backgroundColor = "grey-background";
            }
            return (
                <>
                    {
                        PolygonTop ? <TopFillPolygon /> : ""
                    }
                    <div className={`price-card-container ${backgroundColor}`}>

                        <div className="cards-serv">

                            <h2 className="title-servCards">How Much Will Cost?</h2>
                            
                            <div className="uk-child-width-1-3@m uk-child-width-1-2@s uk-grid-match uk-grid uk-text-center" uk-grid="true" id="margin-cardpage-nopad">

                                {
                                    Services.map((item, index) => {
                                        return (

                                            <div className="price-card-container">
                                                <div className="uk-card uk-card-default uk-card-body" id="price-card-content">
                                                    <div className="card-info">
                                                        <GatsbyImage image={item.Image.gatsbyImageData}></GatsbyImage>
                                                        <h2 className="uk-card-title">{item.Title}</h2>
                                                        <CustomMarkdown children={item.Description}></CustomMarkdown>

                                                    </div>

                                                    <div className="price">
                                                        {/* {console.log("test" , {Discount})} */}
                                                        <p>£{item.Price - Math.round(item.Price * Discount)}</p>
                                                    </div>
                                                </div>
                                            </div>

                                        )

                                    })
                                }
                            </div>
                        </div>
                    </div>
                    {
                        PolygonBottom ? <BottomFillPolygon /> : ""

                    }
                </>
            )
        default:
            return ("Change type")
    }
}

export default PriceCard;