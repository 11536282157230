import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import CustomMarkdown from "../../utils/customMarkdown";
import BottomFillPolygon from "../bottom-fill-polygon/bottom-fill-polygon";
import Button from "../button/button";
import TopFillPolygon from "../top-fill-polygon/top-fill-polygon";
import './cta-list.scss';

const CTAList = ({ Type, Content, FooterText, Cards, BackgroundColor, PolygonBottom, PolygonTop }) => {

    switch (Type) {
        case "our-service":
            let backgroundColor = "";

            switch (BackgroundColor) {
                case "White":
                    backgroundColor = "white-background";
                    break;
                case "Blue":
                    backgroundColor = "blue-background";
                    break;
                case "White":
                    backgroundColor = "grey-background";

            }
            return (
                <>
                    {
                        PolygonTop ? <TopFillPolygon /> : ""
                    }
                    <div className={`cta-list-container ${backgroundColor}`}>
                        <div className="cta-list-content">
                            <div className="cta-list-header">
                                {
                                    Content ? <CustomMarkdown children={Content} className={backgroundColor === "blue-background" ? 'white-text cta-list-title' : ""}></CustomMarkdown> : ""
                                }
                            </div>
                            <div className="cta-list-cards">
                                <div className="uk-child-width-1-3@m uk-child-width-1-2@s uk-grid card-grid" uk-grid="true">
                                    {
                                        Cards ? Cards.map((item, index) => {
                                            var btnType = "";
                                            switch (item.TextColor) {
                                                case "Blue":
                                                    btnType = "secondary"
                                                    break;
                                                case "Green":
                                                    btnType = "primary"
                                                    break;

                                                case "Orange":
                                                    btnType = "third"
                                                    break;

                                                default:
                                                    btnType = "primary"

                                            }
                                            return (

                                                <div className={`card-container ${index == 0 ? 'uk-first-column' : ''}`} key={index}>
                                                    <div className="uk-card card-content uk-card-default">
                                                        <div className="uk-card-media-top top-card">
                                                            <GatsbyImage image={item.Image.gatsbyImageData} alt=""></GatsbyImage>
                                                        </div>
                                                        <div className="uk-card-body">
                                                            {
                                                                item.Title ? <CustomMarkdown children={item.Title} className={`${btnType}`}></CustomMarkdown> : ""
                                                            }
                                                            {
                                                                item.Subtitle ? <CustomMarkdown children={item.Subtitle}></CustomMarkdown> : ""
                                                            }
                                                            <div className="btn">
                                                                {
                                                                    item.CTAUrl ? <Button href={item.CTAUrl} btnType={btnType} label={item.CTALabel} /> : ""
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : ""
                                    }
                                </div>
                                {
                                    FooterText ? <div className="text-center margin-top"><p className={backgroundColor === "blue-background" ? 'white-text' : ""}>{FooterText}</p></div> : ""
                                }
                            </div>

                        </div>
                    </div>
                    {
                        PolygonBottom ? <BottomFillPolygon /> : ""

                    }
                </>

            )
        default:
            return ("Change type")
    }


}

export default CTAList;