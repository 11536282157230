import React from "react";
import "./bottom-fill-polygon.scss";


const BottomFillPolygon = () => {

    return(

        <div className="bottom-fill-polygon"></div>

    )
}

export default BottomFillPolygon;